window.__tnt || (window.__tnt = {});
if (typeof NodeList.prototype.forEach !== "function") { NodeList.prototype.forEach = Array.prototype.forEach; } /** IE11 foreach polyfill */
__tnt.video || (__tnt.video = {});
__tnt.video.blox || (__tnt.video.blox = {});
__tnt.video.plugins || (__tnt.video.plugins = {});
__tnt.video.advertising || (__tnt.video.advertising = function(player){__tnt.log("No video ad callback configured.")});

if(typeof(window.CustomEvent)==='function'){
    window.videoReady = new CustomEvent('tntVideoReady');
    window.videoEnded = new CustomEvent('tntVideoEnded');
}else{
    window.videoReady = document.createEvent('Event');
    window.videoEnded = document.createEvent('Event');
    videoReady.initEvent('tntVideoReady',true,true,{});
    videoEnded.initEvent('tntVideoEnded',true,true,{});
}

__tnt.video.mimetype = function(a){
    var mimetype = "video/mp4";
    if(typeof(a)=="string"){
        if(a.match(/\.flv/)!=null){
            mimetype = "video/x-flv";
        }else if(a.match(/\.m3u8/)!=null){
            mimetype = "application/x-mpegURL";
            if(videojs.browser.IS_ANY_SAFARI){ mimetype = 'application/vnd.apple.mpegurl'; }
        }
    }        
    return mimetype;
};

__tnt.video.getMarker = function(player, callback) {
    if (__tnt.user.loggedIn && player.el_.dataset.premium) {
        // Get marker 
        fetch( __tnt.scrubUrl('/tncms/video/get_marker?asset_id=' + player.el_.dataset.uuid))
            .then(function(response) {
                if (response.status !== 200) {
                    __tnt.log("Error. Status Code: " + response.status);
                    return;
                }
            response.json()
                .then(function(data) {
                    // Use marker
                    player.currentTime(data.seconds);

                    __tnt.video.blox.players[player.id()].resumeAt = data.seconds;

                    if(typeof(callback)=="function"){
                        callback(player, data);
                    }
            });
        })
        .catch(function(err) {
            __tnt.log("Fetch Error: ", err);
        });
    }
}

__tnt.video.setMarker = function(player) {
    var seconds = Math.floor(player.currentTime()),
        xhr = new XMLHttpRequest();

    if (__tnt.user.loggedIn && player.el_.dataset.premium) {
        if(!__tnt.video.blox.players[player.id()].adPlaying){
            if (player.remainingTime() <= 15) {
                // Clear marker and stop sending if near video end
                __tnt.video.clearMarker(player);
                return;
            } else if(seconds) {
                // Track reported position
                __tnt.video.blox.players[player.id()].reported_pos = seconds;

                // Send marker
                xhr.open('post', __tnt.scrubUrl('/tncms/video/set_marker/'));
                xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
                xhr.onreadystatechange = function() {
                    if (xhr.readyState === 4) {
                        if (xhr.status !== 200) {
                            __tnt.log('Request failed ' + xhr.status);
                        }
                    }
                };
                xhr.send(encodeURI('asset_id=' + player.el_.dataset.uuid + '&seconds=' + seconds));
            }
        }
    }
}

__tnt.video.videoDataEvent = function(player, event) {
    var eventName = '',
        eventCategory = 'video',
        eventAction = '',
        eventLabel = '',
        curTime = Math.floor(player.currentTime()),
        percentComplete = Math.round(100 - ((player.el_.dataset.duration - curTime) / player.el_.dataset.duration) * 100),
        bTrackProgress = false,
        bStreaming = 'false';
    
    if (!player.el_.dataset.duration || player.el_.dataset.duration == '') {
        bStreaming = 'true';
        percentComplete = '';
    }
    
    if (percentComplete >= 5 && percentComplete <= 10) { percentComplete = 10; bTrackProgress = true;}
    else if (percentComplete >= 20 && percentComplete <= 25) { percentComplete = 25; bTrackProgress = true;}
    else if (percentComplete >= 45 && percentComplete <= 50) { percentComplete = 50; bTrackProgress = true;}
    else if (percentComplete >= 70 && percentComplete <= 75) { percentComplete = 75; bTrackProgress = true;}
    else if (percentComplete >= 95 && percentComplete <= 100) { percentComplete = 100; }
    
    /* Set event data */
    if (event == 'video_paused') {
        eventName = 'video_paused';
        eventAction = 'video paused';
        eventLabel = player.el_.dataset.title + ':' + player.el_.dataset.uuid;
        curTime = player.el_.dataset.duration/100 * percentComplete;
    }
    else if (event == 'video_resumed') {
        eventName = 'video_resumed';
        eventAction = 'video resumed';
        eventLabel = player.el_.dataset.title + ':' + player.el_.dataset.uuid;
        curTime = player.el_.dataset.duration/100 * percentComplete;
    } else if (curTime <= 1){
        eventName = 'video_start';
        eventAction = 'video start';
        eventLabel = player.el_.dataset.title + ':' + player.el_.dataset.uuid;
        curTime = 0;
    } else if (percentComplete == 100) {
        eventName = 'video_complete';
        eventAction = 'video ended';
        eventLabel = player.el_.dataset.title + ':' + player.el_.dataset.uuid;
        curTime = player.el_.dataset.duration;
    } else if(bTrackProgress) {
        eventName = 'video_progress';
        eventAction = 'video progress';
        eventLabel = percentComplete + '%:' + player.el_.dataset.title + ':' + player.el_.dataset.uuid;
        curTime = player.el_.dataset.duration/100 * percentComplete;
    } else {
        return;
    }
    
    var tntVideoData = new CustomEvent('tntVideoData', {
      detail: {
          video_uuid: player.el_.dataset.uuid,
          video_title: player.el_.dataset.title,
          video_provider: player.el_.dataset.provider,
          video_duration: player.el_.dataset.duration,
          video_url: player.currentSrc(),
          video_current_time: Math.round(curTime),
          video_progress: percentComplete,
          visible: player.el_.dataset.visible
      }
    });
    
    // Dispatch general data event
    dispatchEvent(tntVideoData);
    console.log(tntVideoData.detail);
    
    // GTM tracking
    try {
        dataLayer.push({
            'event': 'tncms.event.trigger',
                'tncms.event.trigger.category': eventCategory,
                'tncms.event.trigger.action': eventAction,
                'tncms.event.trigger.label': eventLabel,
                'tncms.event.trigger.value': '1',
                'tncms.event.trigger.name': eventName,
                'tncms.event.trigger.video_uuid': player.el_.dataset.uuid,
                'tncms.event.trigger.video_title': player.el_.dataset.title,
                'tncms.event.trigger.video_provider': player.el_.dataset.provider,
                'tncms.event.trigger.video_url': player.currentSrc(),
                'tncms.event.trigger.video_streaming': bStreaming,
                'tncms.event.trigger.video_duration': player.el_.dataset.duration,
                'tncms.event.trigger.video_current_time': Math.round(curTime),
                'tncms.event.trigger.video_percent': percentComplete,
                'tncms.event.trigger.visible': player.el_.dataset.visible
        });
    } catch(e) {
        console.debug('%c dataLayer is not defined', 'color: #990000');
    }
}

__tnt.video.clearMarker = function(player) {
    if (__tnt.user.loggedIn && player.el_.dataset.premium) {
        // Clear marker
        var xhr = new XMLHttpRequest();
        xhr.open('post', __tnt.scrubUrl('/tncms/video/clear_marker'));
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.onreadystatechange = function() {
            if (xhr.readyState === 4) {
                if (xhr.status !== 200) {
                    __tnt.log('Request failed ' + xhr.status); 
                }
            }
        };
        xhr.send(encodeURI('asset_id=' + player.el_.dataset.uuid));
    }
}

__tnt.video.pause = function(player){
    var _playing = __tnt.video.blox.players[player.id()].playing;
    if(__tnt.video.nielsen) { __tnt.video.nielsen.stop(player); }
    if(player.remainingTime()>0.2 && player.currentTime()>0.2 && _playing){
        // Track pause
        __tnt.video.videoDataEvent(player, 'video_paused');
        __tnt.video.blox.players[player.id()].paused = true;
    }
};

__tnt.video.register = function(player){
    __tnt.video.blox.players || (__tnt.video.blox.players = []);
    if(typeof(__tnt.video.blox.players[player.id()])=='undefined'){
        __tnt.video.blox.players.push(player.id());
        __tnt.video.blox.players[player.id()] = {
            src: player.currentSrc(),
            playing: false,
            played: false,
            playLogged: false,
            paused: false,
            stalled: 0,
            lastFrame: 0,
            endcard:true,
            endcardDisplayed:false,
            adPlaying:false,
            resumeAt:0,
            activeTextTrack:null
        };
    }
};
__tnt.video.decompose = function(player){
    var players = [];
    __tnt.video.blox.players.forEach(function(item){  
        if(item!=player){
            players.push(item);
            players[item] = __tnt.video.blox.players[item];
        }
    });
    
    __tnt.video.blox.players = players;
};

__tnt.video.callback || (__tnt.video.callback = function(player){

    var endEvent = function (event) {
        __tnt.video.blox.players[player.id()].played = true;
        var endcard = document.getElementById(player.id()+'-engagement');
        __tnt.video.clearMarker(player);
        __tnt.log("Player - Ended - passed playing check");
        __tnt.video.blox.players[player.id()].playing = false;
        __tnt.video.blox.players[player.id()].playLogged = false;

        if(endcard){
            __tnt.log("Player - Displaying end card");
            __tnt.log("Player - Running engagement module");

            if(!(endcard.querySelectorAll(".engagement-container .engagement-item").length)){
                var assetId = player.id().replace('video-player-','');

                if(__tnt.engagement){
                    if(__tnt.engagement.assets.length && assetId && typeof(__tnt.engagement.assets[assetId].populate())==='function'){
                        player.bigPlayButton.el().style.display = 'none';
                        player.controlBar.el().style.display = 'none';

                        endcard.style.display = 'none';
                        endcard.querySelector('.engagement-spinner').style.display = 'none';

                        player.currentTime(0);
                        player.trigger('loadstart');
                    }
                }
            }else if(endcard.querySelectorAll('.engagement-container .engagement-item').length){
                endcard.classList.remove('hide');
                endcard.style.display = 'block';
            }else{
                endcard.style.display = 'block';
                player.bigPlayButton.el().style.display = 'block';
                endcard.querySelect('.engagement-spinner').style.display = 'none';
            }

            __tnt.video.blox.players[player.id()].endcardDisplayed = true;

            endcard.querySelector('.endcard-replay a').addEventListener('click',function(){
                endcard.style.display = 'none';
                player.play();
            });
        }

        player.el().dispatchEvent(videoEnded);

        if(__tnt.video.nielsen && !__tnt.video.nielsen.ended) { __tnt.video.nielsen.end(player); }
    }
    
    __tnt.video.register(player);
    
    player.on('__tnt.video.initialize',function(event){
        __tnt.video.blox.players[player.id()].playing = false;
        __tnt.video.blox.players[player.id()].paused = false;
        __tnt.video.blox.players[player.id()].reported_pos = false;
        __tnt.video.blox.players[player.id()].reported_percent = 0;
        __tnt.video.getMarker(player);

        if(__tnt.video.nielsen) { __tnt.video.nielsen.sdkinit(player); }
        
        /** Observe player visibility */
        var callback_io = function(entries, player_io) {
            if (entries[0].isIntersecting) {
                player.el_.dataset.visible = 'true';
            } else {
                player.el_.dataset.visible = 'false';
            }
        }
        var player_io = new IntersectionObserver(callback_io);
        player_io.observe(player.el_);

        if (event.target.closest('.tn-mediaplayer') && event.target.closest('.tn-mediaplayer').dataset.autoAdvance) {
            setTimeout(function(){
                player.play();
            }, 100);
        }
    });
    
    player.on('timeupdate',function(event){
        var iCurrentTime = Math.floor(player.currentTime()),
            percentComplete = Math.round(100 - ((player.el_.dataset.duration - iCurrentTime) / player.el_.dataset.duration) * 100);

        if (percentComplete >= 5 && percentComplete <= 10) { percentComplete = 10; }
        else if (percentComplete >= 20 && percentComplete <= 25) { percentComplete = 25; }
        else if (percentComplete >= 45 && percentComplete <= 50) { percentComplete = 50; }
        else if (percentComplete >= 70 && percentComplete <= 75) { percentComplete = 75; }
        else if (percentComplete >= 95 && percentComplete <= 100) { percentComplete = 100; }
        
        if (percentComplete == 10 || percentComplete == 25 || percentComplete == 50 || percentComplete == 75 || percentComplete == 100) {
            if (__tnt.video.blox.players[player.id()].reported_percent < percentComplete) {
                __tnt.video.videoDataEvent(player);
                __tnt.video.blox.players[player.id()].reported_percent = percentComplete;
            }
        }
        
        if(iCurrentTime % 10 == 0 && iCurrentTime != __tnt.video.blox.players[player.id()].reported_pos){
            __tnt.video.blox.players[player.id()].reported_pos = iCurrentTime;
            __tnt.video.setMarker(player);
        }
    });
    
    player.on('play',function(event){
        var _playing = __tnt.video.blox.players[player.id()].playing;
        var _paused = __tnt.video.blox.players[player.id()].paused;
        var assetId = player.id().replace('video-player-','');
        var endcard = document.getElementById(player.id()+'-engagement');
        var endcardDisplayed = __tnt.video.blox.players[player.id()].endcardDisplayed;
        
        __tnt.video.setMarker(player);

        if(_paused){
            // Track resumed
            __tnt.video.videoDataEvent(player, 'video_resumed');
            
            __tnt.video.blox.players[player.id()].paused = false;
        }else if(!(_playing)){
            __tnt.video.blox.players[player.id()].playing = true;

            // ensure that only one blox player is playing 
            if(__tnt.video.blox.players.length > 1){ for(var i = 0; i < __tnt.video.blox.players.length; i++){
                var item = __tnt.video.blox.players[i];

                if(__tnt.video.blox.players[item]){
                    if(player.id() != item && __tnt.video.blox.players[item].playing && videojs(item)){
                        videojs(item).pause();
                    }
                }
            }};

            player.contentEl().querySelector('.vjs-loading-spinner').style.display = "none";
            player.posterImage.el().style.display = "none";

            if(!__tnt.video.blox.players[player.id()].playLogged){
                __tnt.video.blox.players[player.id()].playLogged = true;
                // Track start
                __tnt.video.videoDataEvent(player);
            }

            // ensure that the endcard is within the player
            if(player.contentEl() && endcard){
                player.contentEl().appendChild(endcard);
            }
            
            if(endcard && __tnt.engagement && !endcardDisplayed){
                __tnt.log("Player - On Play - Pre-populating end card");
                
                if(__tnt.engagement.assets){
                    if(__tnt.engagement.assets[assetId]){
                        if(typeof(__tnt.engagement.assets[assetId].populate())==='function'){
                            __tnt.engagement.assets[assetId].populate();
                        }
                    }
                }
                
            }
            if(endcardDisplayed){
                document.getElementById(player.id()+'-engagement').style.display = "none";
            }
        }

        if(__tnt.video.nielsen) { __tnt.video.nielsen.start(player); }
    });
    
    player.on('pause',function(event){
        var container = player.el().closest('.tnt-video-container');
        if(container.getAttribute('data-live') == "true" && !videojs.browser.IS_IOS){
            player.play();
        }else{
            __tnt.video.pause(player);
            __tnt.video.blox.players[player.id()].playing = false;
        }
    });
    
    player.on('fullscreenchange',function(event){
        if(player.isFullscreen()){ player.el().classList.add('is-fullscreen')
        }else{ player.el().classList.remove('is-fullscreen') }
    });
    
    player.tech_.on('ended', endEvent);
    player.on('ended', endEvent);

    player.trigger('__tnt.video.initialize');
});

__tnt.video.handleTags = function(container, callBack){
    var players = [];

    var config = {
        "fluid": true,
        "plugins":__tnt.video.plugins,
        "crossorigin":"anonymous",
        "html5":{
            "nativeTextTracks": false,
            "nativeAudioTracks": false,
            "nativeVideoTracks": false,
            "hls":{
                "overrideNative": true,
                "handleManifestRedirects": true,
                "cacheEncryptionKeys":true
            }
        }
    };

    for(var i = 0; i < container.length; i++){
        var element = container[i];
        
        var debugOutput = document.createElement('div');
        element.setAttribute('controls','true');
        var classList = ['videojs','vjs-7','vjs-default-skin','vjs-big-play-centered','vjs-16-9'];
        classList.forEach(function(c){
            element.classList.add(c);
        });
        
        videojs(element,config,function(){
            players.push(this);
            player = this;

            if(typeof(__tnt.video.advertising)==='function'){
                __tnt.video.advertising(player);
            }

           if(navigator.userAgent.indexOf('MSIE')!==-1 || navigator.appVersion.indexOf('Trident/') > 0 || navigator.userAgent.indexOf('Edge/')){
               document.getElementById(player.id()).style['padding-top'] = '56.25%';
           }


            __tnt.video.callback(player);
            player.trigger('loadstart');

            canAutoplay.video({'inline':true}).then(function(data){
                var bodyTag = document.getElementsByTagName('body')[0];
                var playerId = element.id;
                    playerId = playerId.replace('_html5_api','');
                var playerElement = document.getElementById(playerId);
                var assetUuid = playerId.replace('video-player-','');
                var isAsset = bodyTag.classList.contains('blox-asset');
                var isThisAsset = bodyTag.classList.contains('uuid-'+assetUuid);
                var isPrimaryVideoAsset = playerElement.closest('#asset-video-primary');
                var allowAutoplay = playerElement.closest('.tnt-media-autoplay');

                if(data.error!=null){
                    console.log(data.error);
                }

                if (data.result === true && (isAsset && (isThisAsset || isPrimaryVideoAsset) || allowAutoplay) ) {
                    try{
                        var autoplayObserver = new IntersectionObserver(function(entries){
                            if(entries[0].intersectionRatio <= 0){
                                // Pause on leave frame / picture in picture mode could go here.
                            }else{
                                if(!__tnt.video.blox.players[playerId].playing && !__tnt.video.blox.players[player.id()].endcardDisplayed){
                                    if(!__tnt.video.blox.players[playerId].played){
                                        videojs(playerId).play();
                                    }else{
                                        autoplayObserver.disconnect();
                                    }
                                }else if(__tnt.video.blox.players[player.id()].endcardDisplayed){
                                    autoplayObserver.disconnect();
                                }
                            }
                        });

                        autoplayObserver.observe(document.getElementById(playerId));
                    }catch(e){
                        console.log(e);
                    }
                }
            });
        });
    }
    
    if(typeof(callBack)=='function'){
        callBack(players);
    }
};

__tnt.video.legacy_no_template_support = function(){
    var lplayers = document.querySelectorAll('video.tnt-video,audio.tnt-audio');
    if(lplayers.length){ for(var i = 0; i < lplayers.length; i++){
        if(!lplayers[i].closest('template')){  
            __tnt.video.handleTags(lplayers[i].parentElement.querySelectorAll('video.tnt-video,audio.tnt-audio')); 
        }
    }};
};

document.addEventListener('tntVideoReady', function(event) {
    if(videojs.browser.IE_VERSION == null){
        __tnt.video.handleTags(document.querySelectorAll('video.tnt-video,audio.tnt-audio,video-js.tnt-video'));
    }else{
        __tnt.video.legacy_no_template_support();
    }
});

document.addEventListener("DOMContentLoaded", function(event) {
    setTimeout(function(){
        if(!__tnt.video.vast){
            document.dispatchEvent(videoReady);
        }   
    }, 2500);
});
